
.home {
    font-family: 'Segoe UI', Roboto, Oxygen, sans-serif;
}

a {
    font-size: 18px;
    text-decoration: none;
    color: inherit;
}



.name {
    font-size: 28px;
}

.main-nav {
    position: fixed;
    top:   0;
    left:  0;
    right: 0;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px;
    background-color: #623b32;
}

.about-me {
    min-height: 600px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.about-me h1 {
    font-size: 28px;
    max-width: 400px;
    margin-top: 30px;
    font-weight: bold;
}

.about-me p {
    font-size: 21px;
    margin-top: 20px;
    font-style: italic;
}

.Avatar {
    display: block;
    width: 100%;
}
